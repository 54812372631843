import { createIcon } from "@chakra-ui/icons"

export const tBTCFillBlack = createIcon({
  displayName: "tBTCFillBlack",
  viewBox: "0 0 23 24",
  path: (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5127 23.2266C17.713 23.2266 22.7393 18.2003 22.7393 12C22.7393 5.79974 17.713 0.773438 11.5127 0.773438C5.31244 0.773438 0.286133 5.79974 0.286133 12C0.286133 18.2003 5.31244 23.2266 11.5127 23.2266Z"
        fill="#1D2229"
      />
      <path
        d="M9.65153 11.0594H7.77167V12.9393H9.65153V11.0594Z"
        fill="white"
      />
      <path
        d="M7.77165 9.17957H5.89178V11.0594H7.77165V9.17957Z"
        fill="white"
      />
      <path d="M5.89146 11.0594H4.0116V12.9393H5.89146V11.0594Z" fill="white" />
      <path
        d="M7.77165 12.9392H5.89178V14.8191H7.77165V12.9392Z"
        fill="white"
      />
      <path
        d="M18.4577 9.58446C18.3252 8.20191 17.1319 7.73783 15.6248 7.60528V6.71969H14.4578V7.55476C14.1512 7.55476 13.8376 7.56064 13.5258 7.56652V6.71899H12.3596V7.60424C12.1069 7.60909 10.5548 7.6077 10.5548 7.6077L10.5513 8.63657L11.5252 8.64211V15.3853H10.5517L10.543 16.3996C10.8258 16.3996 12.0841 16.4052 12.3568 16.4069V17.2811H13.5231V16.4277C13.8428 16.4346 14.1529 16.4374 14.4554 16.437V17.2814H15.6227V16.4111C17.5853 16.2993 18.9595 15.8054 19.1301 13.963C19.2685 12.479 18.5709 11.8173 17.4569 11.5481C18.1338 11.202 18.557 10.596 18.4584 9.58412L18.4577 9.58446ZM16.8225 13.7314C16.8225 15.1811 14.3408 15.0154 13.549 15.0157V12.4458C14.3405 12.4458 16.8211 12.2198 16.8215 13.7314H16.8225ZM16.2809 10.1053C16.2809 11.4242 14.21 11.2695 13.5507 11.2695V8.93835C14.211 8.93869 16.2816 8.72967 16.2809 10.1053Z"
        fill="white"
      />
    </svg>
  ),
})
